var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"tag":"section","fluid":""}},[_c('v-btn',{attrs:{"absolute":"","top":"","right":"","fab":"","small":""},on:{"click":function($event){return _vm.downloadcsv()}}},[_c('v-icon',[_vm._v(" fa-download ")])],1),_c('v-data-table',{attrs:{"items":_vm.acls,"headers":[
      { text: 'Liga', value: 'league.name', sortable: false },
      { text: 'Mannschaft', value: 'team.name', sortable: false },
      { text: 'Mannschaftsverantwortliche', value: 'mv', sortable: false },
      { text: '', value: 'mvadd', sortable: false },
      { text: 'Verein', value: 'team.club.name', sortable: false },
      { text: 'Vereinsverantwortliche', value: 'vv', sortable: false },
      { text: '', value: 'vvadd', sortable: false } ],"items-per-page":-1,"sort-by":['league.order','team.name']},on:{"click:row":_vm.gototeam},scopedSlots:_vm._u([{key:"item.mv",fn:function(ref){
    var item = ref.item;
return _vm._l((item.team.acl),function(p){return _c('div',{key:p._id},[_vm._v(" "+_vm._s(_vm._f("person")(p))+" "),_c('v-btn',{attrs:{"fab":"","x-small":"","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.del(p._id, item.team._id, 'team')}}},[_c('v-icon',{staticStyle:{"font-size":"150%"}},[_vm._v("far fa-trash-alt")])],1)],1)})}},{key:"item.mvadd",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"fab":"","small":"","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.openadd(item.team._id, 'team', item.team.name)}}},[_c('v-icon',[_vm._v("fa-plus")])],1)]}},{key:"item.vv",fn:function(ref){
    var item = ref.item;
return _vm._l((item.team.club.acl),function(p){return _c('div',{key:p._id},[_vm._v(" "+_vm._s(_vm._f("person")(p))+" "),_c('v-btn',{attrs:{"fab":"","x-small":"","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.del(p._id, item.team.club._id, 'club')}}},[_c('v-icon',{staticStyle:{"font-size":"150%"}},[_vm._v("far fa-trash-alt")])],1)],1)})}},{key:"item.vvadd",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"fab":"","small":"","text":""},on:{"click":function($event){$event.stopPropagation();return _vm.openadd(item.team.club._id, 'club', item.team.club.name)}}},[_c('v-icon',[_vm._v("fa-plus")])],1)]}}])}),_c('base-material-dialog',{attrs:{"value":_vm.add.open,"icon":"far fa-user-friends","title":((_vm.add.type === 'club' ? 'Verein' : 'Mannschaft') + "svertreter für " + (_vm.add.name) + " hinzufügen"),"color":"primary","actions":[ 'save', 'close' ]},on:{"close":_vm.closeadd,"esc":_vm.closeadd,"save":_vm.saveadd}},[_c('base-edit-dialog-personsuche',{attrs:{"value":_vm._f("person")(_vm.add.fetch),"label":((_vm.add.type === 'club' ? 'Verein' : 'Mannschaft') + "svertreter"),"id":"null","clearable":""},on:{"input":function (ref) {
    var value = ref.value;
 _vm.add.fetch = null; _vm.add.p = value }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }